<template>
  <div>

    <h1 class="text-2xl font-bold line-height mb-8">Repayment Cards</h1>


    <template v-if="cards.loading || form.loading">
      <sm-loader class="mb-10" />
    </template>
    <template v-else-if="cards.data.length">
      
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-8 mb-10" style="letter-spacing: .1em">

        <template v-for="(card, n) in cards.data">
          <div class="col-span-1 flex flex-row items-center bg-purple-100 px-6 py-4 text-sm relative" :key="n">
            <div class="absolute left-0 w-2px h-20px rounded" :class="{[`bg-${providers[card.card_type.trim()].color}-500`]: true}" />
            <div class="mr-6" style="width: 45px">
              <img :src="providers[card.card_type.trim()].logo" alt="Visa" style="height: 26px">
            </div>
            <span class="text-gray-700" v-html="`•••• &nbsp;${card.last4}`" />
            <span class="text-gray-700 ml-auto">{{ card.exp_month }}/{{ card.exp_year.match(/\d{2}$/)[0] }}</span>
          </div>
        </template>

        <!-- <div class="col-span-1 flex flex-row items-center bg-purple-100 px-6 py-4 text-sm relative">
          <div class="absolute left-0 w-2px h-20px rounded" :class="{[`bg-${providers.mastercard.color}-500`]: true}"></div>
          <div class="mr-6" style="width: 45px">
            <img :src="providers.mastercard.logo" alt="Mastercard" style="height: 26px">
          </div>
          <span class="text-gray-700" v-html="'•••• &nbsp;0029'"></span>
          <span class="text-gray-700 ml-auto">08/23</span>
        </div> -->

      </div>

    </template>
    <template v-else>
      <div class="text-sm text-gray-700 mb-10">You have no repayment cards at the moment. Click the button below to add a new one.</div>
    </template>

    <div style="letter-spacing: .1em">

      <div class="mb-8">
        <paystack
          class="btn btn-blue"
          :amount="100"
          :callback="addCard"
          :disabled="form.loading"
        >
          <ion-icon name="card-outline" class="mr-2 text-xl" />
          <template v-if="form.loading">
            Verifying
          </template>
          <template v-else>
            <span>Add New</span>
          </template>
        </paystack>
      </div>
      
    </div>


    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">

      <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-sm mb-10">
        Card Saved successfully!
      </div>

      <router-link :to="{name: 'dashboard'}" class="btn btn-blue">
        Go to Dashboard
      </router-link>
      
    </modal>

  </div>
</template>
<script>
  export default {
    data() {
      return {
        providers: {
          mastercard: {
            logo: require('@/assets/mastercard.svg'),
            color: 'red'
          },
          visa: {
            logo: require('@/assets/visa-blue.svg'),
            color: 'blue'
          },
        },

        cards: this.$options.basicForm([]),
        form: this.$options.basicForm([]),
      }
    },
    beforeMount() {
      this.getCards();
    },
    mounted() {
      
    },
    methods: {
      async getCards() {
        this.cards.loading = true;

        await this.sendRequest('corporate.repaymentCards.all', {
          success: response => {
            this.cards.data = response.data.cards;
          },
          error: error => {
            this.cards.error = error;
          }
        });

        this.cards.loading = false;
      },
      async addCard({ reference }) {
        this.form.loading = true;

        await this.sendRequest('corporate.repaymentCards.new', {
          data: {
            company_id: this.user.company_id,
            reference
          },
          success: () => {
            this.$refs.successModal.open();
            this.getCards();
          },
          error: error => {
            console.log(error);
            this.form.error = error;
          }
        });

        this.form.loading = false;
      }
    }
  }
</script>